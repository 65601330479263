<template>
  <div :key="chartKey">
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <mfr-heat-map
          :date-range="dateRange"
          :height="fullHeight"
        ></mfr-heat-map>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <market-share-column-chart></market-share-column-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import MfrHeatMap from '@/components/charts/MfrHeatMap.vue'
import { windowAttributes } from '@/util/functions/functionsData'
import { handleResize } from '@/util/functions'
import marketShareColumnChart from '@/components/charts/marketShareColumnChart.vue'

export default {
  name: 'MarketOverview',
  components: {
    MfrHeatMap,
    marketShareColumnChart,
  },
  setup() {
    return {}
  },
  data() {
    return {
      chartKey: 0,
      cardHeight: 'auto',
      fullHeight: 'auto',
      windowAttributes,
      handleResize,
      dateRange: [],
    }
  },
  created() {
    window.addEventListener('resize', this.debouncedMatchHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.debouncedMatchHeight)
    this.$root.$off('mfr-market-share-pie-chart')
    this.$root.$off('mfr-heat-map')
    this.$root.$off('refresh-charts')
    this.$root.$off('mfr-heat-map-filter-by-mfr')
    this.$root.$off('reset-mfr-heat-map-filter-by-mfr')
  },
  async mounted() {
    await this.matchHeight()
  },
  methods: {
    async matchHeight() {
      this.windowAttributes = await this.handleResize()
      this.cardHeight = ((this.windowAttributes.height - 150) / 2).toString()
      this.fullHeight = (this.windowAttributes.height - 300).toString()
    },
    debouncedMatchHeight: debounce(function () {
      this.matchHeight()
    }, 500),
    updateFilter(date) {
      this.dateRange = date

      this.$root.$emit('refresh-charts')
    },
  },
}
</script>
